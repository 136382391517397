import { render, staticRenderFns } from "./rule-editor.vue?vue&type=template&id=08d78205&"
import script from "./rule-editor.vue?vue&type=script&lang=ts&"
export * from "./rule-editor.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VCard } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VExpansionPanel } from 'vuetify/lib/components/VExpansionPanel';
import { VExpansionPanelContent } from 'vuetify/lib/components/VExpansionPanel';
import { VExpansionPanelHeader } from 'vuetify/lib/components/VExpansionPanel';
import { VExpansionPanels } from 'vuetify/lib/components/VExpansionPanel';
import { VForm } from 'vuetify/lib/components/VForm';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VTab } from 'vuetify/lib/components/VTabs';
import { VTabItem } from 'vuetify/lib/components/VTabs';
import { VTabs } from 'vuetify/lib/components/VTabs';
import { VTabsItems } from 'vuetify/lib/components/VTabs';
installComponents(component, {VCard,VCol,VContainer,VDivider,VExpansionPanel,VExpansionPanelContent,VExpansionPanelHeader,VExpansionPanels,VForm,VListItem,VListItemContent,VListItemTitle,VRow,VTab,VTabItem,VTabs,VTabsItems})
