































































































































































































import { Component, Vue, Prop } from "vue-property-decorator";
import EcTextField from "common-components/src/components/form/ec-text-field.vue";
import { TableHeader } from "@/components/layout/models/table.d";
import { Field } from "@/models/form";
import { RuleParameterValue } from "@/models/library-maintenance";
import { operators } from "@/models/dropdowns";
import RuleKeywordSuggestion from "./rule-keyword-suggestion.vue";
import { RULE_PARAMETER_TYPES } from "@/models/rule";
import { isFeatureEnabled } from "@/config";
import { KeywordWithType } from "@/models/keyword-suggestion";

@Component({
  components: {
    EcTextField,
    RuleKeywordSuggestion
  }
})
export default class RuleParameterEditor extends Vue {
  @Prop({ required: true }) field!: Field;

  parameterError = "";
  RULE_PARAMETER_TYPES = RULE_PARAMETER_TYPES;

  get keywordSuggestionsEnabled() {
    return isFeatureEnabled("KeywordSuggestion");
  }

  get allTerms() {
    const allTerms = this.field.value
      .filter((item: any) => item.type === RULE_PARAMETER_TYPES.Term && item.value !== "")
      .map((item: any) => item.value.toUpperCase());
    const dedupedTerms = [...new Set(allTerms)];
    return dedupedTerms;
  }

  onTermClicked(searchTerm: KeywordWithType, rowClicked: any, rowIndex: number) {
    let newValue: string;
    if (searchTerm.type === "nextWord") {
      newValue = `${rowClicked.value} ${searchTerm.name}`;
    } else {
      newValue = `${searchTerm.name} ${rowClicked.value}`;
    }
    this.addRow(rowIndex + 1, rowClicked.name, rowClicked.type, newValue);
  }

  parameterHeaders: TableHeader[] = [
    {
      text: "Name",
      value: "name",
      sortable: false,
      cellClass: "text-no-wrap px-2",
      width: "1px"
    },
    {
      text: "Value",
      value: "value",
      sortable: false,
      cellClass: "px-2"
    },
    {
      text: "Operator",
      value: "operator",
      sortable: false,
      width: "160px",
      class: "text-no-wrap px-0",
      cellClass: "px-2"
    },
    {
      text: "Suppressed",
      value: "is-suppressed",
      sortable: false,
      align: "center",
      class: "text-no-wrap px-0",
      cellClass: "px-2"
    },
    {
      text: "Regex",
      value: "is-regex",
      sortable: false,
      align: "center",
      class: "text-no-wrap px-0",
      cellClass: "px-2"
    },
    {
      text: "Unordered",
      value: "unordered",
      sortable: false,
      align: "center",
      class: "text-no-wrap px-0",
      cellClass: "px-2"
    },
    {
      text: "Fuzziness",
      value: "fuzziness",
      sortable: false,
      width: "7ch",
      align: "center",
      class: "text-no-wrap px-0",
      cellClass: "px-2"
    },
    {
      text: "Proximity",
      value: "proximity",
      sortable: false,
      width: "7ch",
      align: "center",
      class: "text-no-wrap px-0",
      cellClass: "px-2"
    },
    {
      text: "",
      value: "add",
      sortable: false,
      width: "1px"
    },
    {
      text: "",
      value: "remove",
      sortable: false,
      width: "1px"
    }
  ];

  operators: any[] = operators;

  removeRow(index: number) {
    this.field.value.splice(index, 1);
  }

  addRow(newIndex: number, name: string, type: number, value = "") {
    const array = this.field.value;
    const newValue: RuleParameterRow = {
      name,
      value,
      type,
      "allow-multiple": true,
      unordered: false,
      "is-regex": false,
      fuzziness: 0,
      "is-suppressed": false,
      proximity: 0
    };

    array.splice(newIndex, 0, newValue);

    if (newIndex == array.length) window.scrollTo(0, document.body.scrollHeight + 100);
  }

  moreThanOneValue(valueName: string) {
    const valuesCount = this.field.value.filter(
      (parmeter: { name: string }) => parmeter.name === valueName
    ).length;
    return valuesCount > 1;
  }

  isParameterLastValue(value: string, name: string, index: number) {
    const array = this.field.value as RuleParameterValue[];
    return index === array.length - 1 || array[index + 1].name !== name;
  }

  regexChangedFor(item: any) {
    if (item["is-regex"]) {
      item.fuzzinessEnabled = false;
    } else {
      item.fuzzinessEnabled = false;
    }
  }

  nextParameter(event: KeyboardEvent) {
    event.target;
  }
}

declare interface RuleParameterRow {
  name: string;
  value: string;
  type: number;
  "allow-multiple": boolean;
  unordered: boolean;
  fuzziness: number;
  "is-regex": boolean;
  "is-suppressed": boolean;
  operator?: string;
  proximity: number;
}
