var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticStyle:{"width":"100%"}},[_c('v-label',[_vm._v("Parameters")]),_c('v-data-table',{key:_vm.field.value.name,ref:"paramstable",attrs:{"headers":_vm.parameterHeaders,"items":_vm.field.value,"hide-default-footer":true,"row-key":"value","itemsPerPage":-1,"dense":""},scopedSlots:_vm._u([{key:"item.value",fn:function(ref){
var item = ref.item;
var index = ref.index;
return [(_vm.keywordSuggestionsEnabled && item.type == _vm.RULE_PARAMETER_TYPES.Term)?_c('rule-keyword-suggestion',{ref:"keywordSuggestion",staticStyle:{"min-width":"20ch"},attrs:{"exclusions":_vm.allTerms},on:{"click:add":function($event){return _vm.onTermClicked($event, item, index)}},model:{value:(item.value),callback:function ($$v) {_vm.$set(item, "value", $$v)},expression:"item.value"}}):_c('v-text-field',{staticClass:"rule-parameter-value",attrs:{"type":item.type === _vm.RULE_PARAMETER_TYPES.Number ? 'number' : undefined,"hide-details":""},on:{"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"down",40,$event.key,["Down","ArrowDown"])){ return null; }return _vm.nextParameter($event)}},model:{value:(item.value),callback:function ($$v) {_vm.$set(item, "value", $$v)},expression:"item.value"}})]}},{key:"header.operator",fn:function(ref){
var header = ref.header;
return [_vm._v(" "+_vm._s(header.text)+" "),_c('v-tooltip',{attrs:{"open-delay":"300","bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"cursor-pointer",attrs:{"small":""}},'v-icon',attrs,false),on),[_vm._v(" mdi-help-circle-outline ")])]}}],null,true)},[_c('p',[_vm._v(" Sets optional operator type to use (starts with (BEGINS), ends with (ENDS) or contains (CONTAINS)). ")])])]}},{key:"item.operator",fn:function(ref){
var item = ref.item;
return [(item.type == _vm.RULE_PARAMETER_TYPES.Term)?_c('v-select',{attrs:{"items":_vm.operators,"disabled":item['is-regex'],"hide-details":""},model:{value:(item['operator']),callback:function ($$v) {_vm.$set(item, 'operator', $$v)},expression:"item['operator']"}}):_vm._e()]}},{key:"header.is-suppressed",fn:function(ref){
var header = ref.header;
return [_vm._v(" "+_vm._s(header.text)+" "),_c('v-tooltip',{attrs:{"open-delay":"300","bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"cursor-pointer",attrs:{"small":""}},'v-icon',attrs,false),on),[_vm._v("mdi-help-circle-outline")])]}}],null,true)},[_c('p',[_vm._v("Marks a given term parameter as suppressed.")]),_c('p',[_vm._v("This will be used by the rule but not produce a hit entry.")])])]}},{key:"item.is-suppressed",fn:function(ref){
var item = ref.item;
return [(item.type == _vm.RULE_PARAMETER_TYPES.Term)?_c('div',{staticClass:"d-flex justify-center"},[_c('v-switch',{attrs:{"hide-details":""},model:{value:(item['is-suppressed']),callback:function ($$v) {_vm.$set(item, 'is-suppressed', $$v)},expression:"item['is-suppressed']"}})],1):_vm._e()]}},{key:"header.is-regex",fn:function(ref){
var header = ref.header;
return [_vm._v(" "+_vm._s(header.text)+" "),_c('v-tooltip',{attrs:{"open-delay":"300","bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"cursor-pointer",attrs:{"small":""}},'v-icon',attrs,false),on),[_vm._v("mdi-help-circle-outline")])]}}],null,true)},[_vm._v(" Marks this parameter as a regular expression. ")])]}},{key:"item.is-regex",fn:function(ref){
var item = ref.item;
return [(item.type == _vm.RULE_PARAMETER_TYPES.Term)?_c('div',{staticClass:"d-flex justify-center"},[_c('v-switch',{attrs:{"hide-details":""},on:{"change":function($event){return _vm.regexChangedFor(item)}},model:{value:(item['is-regex']),callback:function ($$v) {_vm.$set(item, 'is-regex', $$v)},expression:"item['is-regex']"}})],1):_vm._e()]}},{key:"header.unordered",fn:function(ref){
var header = ref.header;
return [_vm._v(" "+_vm._s(header.text)+" "),_c('v-tooltip',{attrs:{"open-delay":"300","bottom":"","max-width":"400"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"cursor-pointer",attrs:{"small":""}},'v-icon',attrs,false),on),[_vm._v("mdi-help-circle-outline")])]}}],null,true)},[_vm._v(" When enabled, the term will match regardless of the order of the words. ")])]}},{key:"item.unordered",fn:function(ref){
var item = ref.item;
return [(item.type == _vm.RULE_PARAMETER_TYPES.Term)?_c('div',{staticClass:"d-flex justify-center"},[_c('v-switch',{attrs:{"disabled":item['is-regex'],"hide-details":""},model:{value:(item['unordered']),callback:function ($$v) {_vm.$set(item, 'unordered', $$v)},expression:"item['unordered']"}})],1):_vm._e()]}},{key:"header.fuzziness",fn:function(ref){
var header = ref.header;
return [_vm._v(" "+_vm._s(header.text)+" "),_c('v-tooltip',{attrs:{"open-delay":"300","bottom":"","max-width":"400"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"cursor-pointer",attrs:{"small":""}},'v-icon',attrs,false),on),[_vm._v("mdi-help-circle-outline")])]}}],null,true)},[_vm._v(" Specifies the fuzziness for this parameter. This is used to match spelling mistakes. For example, a value of 10% will match even if 10% of the letters in the term are different. ")])]}},{key:"item.fuzziness",fn:function(ref){
var item = ref.item;
return [(item.type == _vm.RULE_PARAMETER_TYPES.Term)?_c('v-text-field',{staticClass:"fuzziness",attrs:{"disabled":item['is-regex'],"type":"number","suffix":"%","hide-details":"","min":"0","max":"100"},model:{value:(item.fuzziness),callback:function ($$v) {_vm.$set(item, "fuzziness", $$v)},expression:"item.fuzziness"}}):_vm._e()]}},{key:"header.proximity",fn:function(ref){
var header = ref.header;
return [_vm._v(" "+_vm._s(header.text)+" "),_c('v-tooltip',{attrs:{"open-delay":"300","bottom":"","max-width":"400"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"cursor-pointer",attrs:{"small":""}},'v-icon',attrs,false),on),[_vm._v("mdi-help-circle-outline")])]}}],null,true)},[_vm._v(" Specifies the proximity for this parameter. With a proximity value set, terms can be separated by that number of words. ")])]}},{key:"item.proximity",fn:function(ref){
var item = ref.item;
return [(item.type == _vm.RULE_PARAMETER_TYPES.Term)?_c('v-text-field',{staticClass:"proximity",attrs:{"disabled":item['is-regex'],"type":"number","hide-details":"","min":"0","max":"100"},model:{value:(item.proximity),callback:function ($$v) {_vm.$set(item, "proximity", $$v)},expression:"item.proximity"}}):_vm._e()]}},{key:"item.remove",fn:function(ref){
var item = ref.item;
var index = ref.index;
return [(_vm.moreThanOneValue(item.name))?_c('v-btn',{attrs:{"fab":"","elevation":"1","color":"warning","x-small":""},on:{"click":function($event){return _vm.removeRow(index)}}},[_c('v-icon',[_vm._v("mdi-delete")])],1):_vm._e()]}},{key:"item.add",fn:function(ref){
var item = ref.item;
var index = ref.index;
return [(item['allow-multiple'] && _vm.isParameterLastValue(item.value, item.name, index))?_c('v-btn',{attrs:{"fab":"","elevation":"1","color":"success","x-small":""},on:{"click":function($event){return _vm.addRow(index + 1, item.name, item.type)}}},[_c('v-icon',[_vm._v("mdi-plus")])],1):_vm._e()]}}])}),_c('div',{staticClass:"v-messages theme--light error--text",attrs:{"role":"alert"}},[_c('div',{staticClass:"v-messages__wrapper"},[_c('div',{staticClass:"v-messages__message message-transition-enter-to"},[_vm._v(" "+_vm._s(_vm.parameterError)+" ")])])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }