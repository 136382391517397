
































































































































import { Component, Vue, Prop, Watch, Ref } from "vue-property-decorator";
import {
  OldParameterValueViewModel,
  ParameterViewModel,
  Rule,
  RuleUpsertModel,
  Template,
  TemplateParameter,
  RuleParameterValue
} from "@/models/library-maintenance.d";
import { ApiError } from "@/models/hal.d";
import * as validation from "@/models/validation";
import MetadataEditor from "@/components/library-maintenance/metadata-editor.vue";
import RuleParameterEditor from "@/components/library-maintenance/rule-parameter-editor.vue";
import EcTextField from "common-components/src/components/form/ec-text-field.vue";
import EcSelect from "common-components/src/components/form/ec-select.vue";
import HdQueryEditor from "@/components/form/hd-query-editor.vue";
import { FieldList, Field, SelectListItem } from "@/models/form";
import { TemplateRenderer } from "@/parser/TemplateVisitors";
import { Dictionary } from "vue-router/types/router";
import RuleTest from "./rule-test.vue";

@Component({
  components: {
    MetadataEditor,
    EcTextField,
    EcSelect,
    HdQueryEditor,
    RuleParameterEditor,
    RuleTest
  }
})
export default class RuleEditor extends Vue {
  @Prop({ default: () => ({}) }) rule!: Rule;

  @Prop({ default: () => [] }) metadataKeys!: SelectListItem[];

  @Prop({ default: () => [] }) apiErrors!: ApiError[];

  @Prop({ default: () => [] }) templates!: Template[];

  @Prop() editing!: boolean;

  @Ref() form!: HTMLFormElement;

  @Ref() queryEditor!: HdQueryEditor;

  @Ref() templateSelect!: any;

  template: Template | null = null;

  get usingTemplate() {
    return this.template !== null;
  }

  panel: number[] = [0, 1];

  tab = 0;

  deselectTemplate() {
    this.$nextTick(() => {
      this.fields["template-id"].value = null;
      this.template = null;
      this.templateSelect.blur();
      this.fields.condition.value = this.rule.condition;
      this.fields.metadata.value = this.rule.metadata;
    });
  }

  @Watch("template")
  onTemplateChanged() {
    this.templateField.value = this.template?.condition;
  }

  @Watch("fields.parameters", { deep: true })
  onParametersChanged() {
    this.renderTemplate();
  }

  renderTemplate() {
    if (!this.template) return;

    const parameters = this.fields.parameters.value as OldParameterValueViewModel[];
    const parametersDict = parameters.reduce((a, b) => {
      if (b.value.trim() == "") return a;

      if (b.name in a) {
        a[b.name].push(b);
      } else {
        a[b.name] = [b];
      }
      return a;
    }, {} as Dictionary<OldParameterValueViewModel[]>);

    const rendererResults = TemplateRenderer.render(this.template.condition, parametersDict);
    this.fields.condition.value = rendererResults.output;

    this.parameterWarnings = rendererResults.messages;
  }

  parameterError = "";
  parameterWarnings: string[] = [];
  fieldList = new FieldList({
    name: new Field("", "Name", [validation.required(), validation.maxLength(488)], []),
    description: new Field("", "Description", [validation.maxLength(200)], []),
    condition: new Field("", "Condition", [validation.required()], []),
    metadata: new Field({}, "Metadata"),
    "template-id": new Field(null, "Template"),
    parameters: new Field([], "Parameters", [])
  });

  templateField = new Field(null, "Template");

  get fields() {
    return this.fieldList.fields;
  }

  @Watch("rule", { immediate: true })
  onValueChanged() {
    this.reset();
  }

  @Watch("apiErrors")
  onErrorsChanged() {
    this.fieldList.addAllErrors(this.apiErrors);
    this.parameterError = this.apiErrors
      .filter(x => x.property.includes("Parameters"))
      .map(x => x.message)
      .join(", ");
  }

  showHideParametersEditor(templateId: string) {
    this.template = this.templates.find(template => template.id === templateId) || null;

    if (this.template === null) {
      this.fields.parameters.value = [];
      this.fields.metadata.value = this.rule.metadata;
      this.fields.condition.value = "";
      return;
    }

    const ruleParametersWithValues = this.AppendRuleParameterValuesTo(
      this.template.parameters,
      this.rule.parameters
    );

    this.fields.parameters.value = this.flattenTheParametersSoWeCanBind(ruleParametersWithValues);
    this.fields.metadata.value = this.template.metadata;
    this.fields.condition.value = "";
  }

  flattenTheParametersSoWeCanBind(parameters: ParameterViewModel[]): OldParameterValueViewModel[] {
    const flattenStructure: OldParameterValueViewModel[] = [];
    for (const param of parameters) {
      if (param.values.length > 0) {
        for (const paramValue of param.values) {
          flattenStructure.push({
            value: paramValue.value,
            name: param.name,
            type: param.type,
            "default-value": param["default-value"],
            "allow-multiple": param["allow-multiple"],
            unordered: paramValue.unordered,
            fuzziness: 100 - paramValue.fuzziness * 100,
            "is-regex": paramValue["is-regex"],
            "is-suppressed": paramValue["is-suppressed"],
            operator: paramValue.operator,
            proximity: paramValue.proximity
          });
        }
      } else {
        flattenStructure.push({
          value: "",
          name: param.name,
          type: param.type,
          "default-value": param["default-value"],
          "allow-multiple": param["allow-multiple"],
          unordered: false,
          fuzziness: 0,
          "is-regex": false,
          "is-suppressed": false,
          operator: undefined,
          proximity: 0
        });
      }
    }
    return flattenStructure;
  }

  AppendRuleParameterValuesTo(
    templateParams: TemplateParameter[],
    ruleParameters: RuleParameterValue[] | undefined
  ): ParameterViewModel[] {
    const parameterViewModels: ParameterViewModel[] = [];
    for (let index = 0; index < templateParams.length; index++) {
      const templateParameter = templateParams[index];
      const ruleParameterVM: ParameterViewModel = {
        values: [],
        type: templateParameter.type,
        "allow-multiple": templateParameter["allow-multiple"],
        name: templateParameter.name,
        "default-value": templateParameter["default-value"]
      };
      if (ruleParameters) {
        const filteredRuleParameters = ruleParameters.filter(
          x => x.name === templateParameter.name
        );
        for (const ruleParameterValue of filteredRuleParameters) {
          ruleParameterVM.values.push({
            value: ruleParameterValue.value,
            unordered: ruleParameterValue.unordered,
            fuzziness: ruleParameterValue.fuzziness,
            "is-regex": ruleParameterValue["is-regex"],
            "is-suppressed": ruleParameterValue["is-suppressed"],
            operator: ruleParameterValue.operator,
            proximity: ruleParameterValue.proximity
          });
        }
      } else {
        ruleParameterVM.values.push({
          value: "",
          unordered: templateParameter["default-unordered"],
          fuzziness: templateParameter["default-fuzziness"],
          "is-regex": templateParameter["default-is-regex"],
          "is-suppressed": templateParameter["default-is-suppressed"],
          operator: templateParameter["default-operator"],
          proximity: templateParameter["default-proximity"]
        });
      }
      parameterViewModels.push(ruleParameterVM);
    }
    return parameterViewModels;
  }

  reset() {
    if (this.tab === 0) this.resetEditor();
    if (this.tab === 1) this.resetTest();
  }

  resetEditor() {
    this.fields.name.value = this.rule.name;
    this.fields.description.value = this.rule.description;
    this.fields.condition.value = this.rule.condition;
    this.fields.metadata.value = this.rule.metadata;
    this.fields["template-id"].value = this.rule["template-id"];
    this.fields.parameters.value = [];

    if (this.rule["template-id"] === undefined) {
      this.template = null;
    } else {
      this.template =
        this.templates.find(template => template.id === this.rule["template-id"]) || null;
      if (this.template !== null) {
        const ruleParametersWithValues = this.AppendRuleParameterValuesTo(
          this.template.parameters,
          this.rule.parameters
        );

        this.fields.parameters.value = this.flattenTheParametersSoWeCanBind(
          ruleParametersWithValues
        );
        this.fields.metadata.value = this.rule.metadata ?? this.template.metadata;
      } else {
        this.deselectTemplate();
      }
    }
  }

  resetTest() {
    const test = this.$refs.ruleTest as RuleTest;
    test.reset();
  }

  fetch(callback: (rule: RuleUpsertModel) => void, validationCallback: () => void) {
    const validQuery =
      !this.editing && this.usingTemplate
        ? true
        : validation.queryExpression(this.fields.condition.value, "");

    const valid = this.form.validate() && validQuery && !this.queryEditor.checkZeroWidthSpace();

    if (!validQuery) {
      let errorMessage = "Condition must be a valid query expression";

      if (this.checkZeroWidthSpace()) {
        errorMessage += " - zero width spaces are not allowed.";
      }
      this.fields.condition.errors?.push(errorMessage);
    }

    if (!valid) {
      validationCallback();
      return;
    }

    const updatedRule: RuleUpsertModel = {
      name: this.fields.name.value,
      description: this.fields.description.value,
      condition: this.fields.condition.value,
      metadata: this.fields.metadata.value,
      "template-id": this.fields["template-id"].value,
      parameters: this.ExtractRuleParametersDataFromModel(this.fields.parameters.value)
    };

    if (updatedRule["template-id"] === "<Please Select>") {
      updatedRule["template-id"] = "";
    }

    callback(updatedRule);
  }

  checkZeroWidthSpace(): boolean {
    return this.queryEditor.checkZeroWidthSpace();
  }

  ExtractRuleParametersDataFromModel(
    parameters: OldParameterValueViewModel[]
  ): RuleParameterValue[] {
    return parameters
      .filter(x => x.value)
      .map(x => ({
        name: x.name,
        value: x.value,
        unordered: x.unordered,
        fuzziness: 1 - x.fuzziness / 100,
        "is-regex": x["is-regex"],
        "is-suppressed": x["is-suppressed"],
        operator: x["operator"],
        proximity: x.proximity
      }));
  }
}
